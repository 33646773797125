/*
* 업무구분: 당첨 결과
* 화면 명: MSPSA033P
* 화면 설명: 당첨 결과
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="당첨 결과" :show-title="true" type="subpage" :topButton="true" @on-header-left-click="fn_close">

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container direction="column" alignV="start" class="con-area pb50">
        <div class="mt30 fs22rem">
          <span class="fwb dsD">{{ rsltInfo.winGiftNm }}</span>
          <span>에 당첨되셨습니다.</span>
        </div>
        <div class="coupon-box fexTy5 mt20">
          <div class="img-area">
            <img :src="blobWinGiftImg" />
          </div>
          <div class="dash-bar" />
          <span class="ml10 fwb fs20rem flex-1">{{ rsltInfo.winGiftNm }}</span>
        </div>
        <div class="coupon-txt mt25">
          <span class="fs18rem">
            <template v-for="(data,k) in winGudnCntnt">
              {{data}} <br :key="k" />
            </template>
          </span>
        </div>
        <div class="text-blue-box">
          <div class="fexTy3 align-item-start">
            <div>
              <span class="dsD fs16rem">휴대폰번호</span>
              <span class="fs17rem fwb">{{ custInfo.celno }}</span>
            </div>
            <mo-button v-if="rsltInfo.giftIconUrl" class="ns-btn-round h28 white" @click="fn_SendLMS">{{ sendBtnMsg }}</mo-button>
          </div>
        </div>
      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue"  name="확인" @click="fn_close">확인</mo-button>
      </div>
    </ur-box-container>

    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="30000" />
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA033P",
    screenId: "MSPSA033P",
    isBackKeyHandle: true,
    components: {
    },
    props: {
      pEvtInfo: {
        type: Object,
        default: {}
      },
      pCustInfo: {
        type: Object,
        default: {}
      },
      pRsltInfo: {
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    async created() {
      this.evtInfo = this.pEvtInfo
      this.custInfo = this.pCustInfo
      this.rsltInfo = this.pRsltInfo

      console.log('당첨결과 정보>>>', this.rsltInfo)
      console.log('고객 정보>>>', this.custInfo)
      console.log('이벤트 정보>>>', this.evtInfo)
      console.log('당첨 이미지 변환시작>>')
      if (!this.$bizUtil.isEmpty(this.rsltInfo.winGiftImgUrl)) {
        let t_FileNmDotPos = this.rsltInfo.winGiftImgUrl.indexOf('.')
        let t_ImgType = this.rsltInfo.winGiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : this.rsltInfo.winGiftImgUrl.substring(t_FileNmDotPos + 1)
        let t_FileNm = this.rsltInfo.winGiftImgUrl.substring(t_FileNmDotPos - 11)
        console.log('중간점검0>>', this.rsltInfo.winGiftImgUrl)
        console.log('중간점검1>>', t_FileNmDotPos)
        console.log('중간점검2>>', t_ImgType)
        console.log('중간점검3>>', t_FileNm)
        let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
        let responseOjb = await this.$bizUtil.httpUrlCall(strUrl)
        console.log('당첨 이미지 변환완료>>', responseOjb)
        if (responseOjb !== null) {
          this.blobWinGiftImg = responseOjb
          console.log('blobWinGiftImg>>', this.blobWinGiftImg)
        }
      }
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_deviceBack)
      // 당첨안내문구
      if (this.evtInfo.winGudnCntnt) {
        this.winGudnCntnt = this.evtInfo.winGudnCntnt.split('\n')
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA033P')
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_deviceBack)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isShow: false,
        evtInfo: {},
        custInfo: {},
        rsltInfo: {},
        winGudnCntnt: '', // 당첨안내문구
        sendBtnMsg: '문자발송요청', // 문자발송 버튼 메세지
        isSent: false, // 문자발송여부
        blobWinGiftImg: '', // 당첨 이미지
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      ontost(){
        this.$refs.stackableSnackbar.show({text:'문자가 발송되었습니다.'});
      },

      /*******************************************************************************
      * Function명: fn_deviceBack
      * 설명: 안드로이드 백키 설정
      *******************************************************************************/
      fn_deviceBack() {
        console.log('fn_deviceBack 안드로이드 백 테스트>>>')
        this.fn_close()
      },

      /*******************************************************************************
      * Function명: fn_SendLMS
      * 설명: 당첨결과 문자 전송
      *******************************************************************************/
        fn_SendLMS () {
        let lv_Vm = this
        console.log('mobslEvtId: ' + lv_Vm.evtInfo.mobslEvtId)
        console.log('mobslEvtRsltSn: ' + lv_Vm.rsltInfo.mobslEvtRsltSn)
        console.log('custCelno: ' + lv_Vm.custInfo.celno)
        console.log('giftIconUrl: ' + lv_Vm.rsltInfo.giftIconUrl)
        console.log('mobslEvtNm: ' + lv_Vm.evtInfo.mobslEvtNm)

        let t_LmsDspchCntnt = '본 문자는 삼성생명 ' + lv_Vm.evtInfo.mobslEvtNm + ' 참여고객님을 대상으로 삼성생명에서 발송하였습니다.\n\n' +
                              '이번 이벤트에 참여해 주셔서 감사합니다.\n' +
                              '하단 URL에 접속하시면 모바일 쿠폰을 사용하실 수 있습니다.\n' +
                              '쿠폰은 기재된 유효기간 내에 사용 부탁드립니다.\n\n ' +
                              '사용 관련 문의 : 1644-4276(쿠폰 고객센터)\n' +
                              '쿠폰 사용 URL : ' + lv_Vm.rsltInfo.giftIconUrl

        let pParams = {
          'mobslEvtId': lv_Vm.evtInfo.mobslEvtId,
          'mobslChnlCustId': lv_Vm.custInfo.chnlCustId,
          'mobslEvtRsltSn': lv_Vm.rsltInfo.mobslEvtRsltSn,
          'custNm': lv_Vm.custInfo.custNm,
          'custCelno': lv_Vm.custInfo.celno,
          'winGiftNm': lv_Vm.rsltInfo.winGiftNm,
          'giftIconUrl': lv_Vm.rsltInfo.giftIconUrl,
          'mobslEvtNm': lv_Vm.evtInfo.mobslEvtNm,
          'lmsDspchCntnt': t_LmsDspchCntnt
        }
        console.log('>>>>>>>>>>>>>>>>', pParams)
        let trnstId = 'txTSSSA34U1'
        let auth = 'U'

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              if (response.body.sucsYn === 'Y') {
                if (lv_Vm.isSent) {
                  lv_Vm.getStore('toast').dispatch('ADD', '문자가 재발송되었습니다.')
                } else {
                  lv_Vm.getStore('toast').dispatch('ADD', '문자가 발송되었습니다.')
                  lv_Vm.sendBtnMsg = '재발송요청'
                  lv_Vm.isSent = true
                }
              } else {
                lv_Vm.getStore('toast').dispatch('ADD', '문자발송 요청이 실패하였습니다.')
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      fn_close() {
        console.log('fn_close 안드로이드 백 테스트>>>')
        this.$emit('onPopupConfirm')
      }
    }
  };
</script>
<style scoped>
</style>
/*
* 업무구분: 사다리 이벤트 참여
* 화면 명: MSPSA038P
* 화면 설명: 사다리 이벤트 참여
* 화면 접근권한:
*/
<template>
  <ur-page-container class="msp" title="사다리 이벤트 참여" :show-title="true" no-back  :topButton="false" @on-header-left-click="fn_close">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container direction="column" alignV="start" class="">
        <div class="top-info">
          <div class="info-block">
            <span class="info-txt">
              <span class="txt fs16rem fwb" v-if="start_ladder === ''">알파벳을 골라보세요</span>
              <span class="txt fs16rem fwb txt-center" v-else>대박나길</span>
            </span>
          </div>
        </div>
        <!-- gift05 / gift04 / gift03 선물수에 따라 변경하여 사용하세요 -->
        <div class="event-ladder-area mt50" :class="{'gift03' : templBkgTypCd === '3', 'gift04' : templBkgTypCd === '4', 'gift05' : templBkgTypCd === '5','select-a' : start_ladder === 'a', 'select-b' : start_ladder === 'b', 'select-c' : start_ladder === 'c', 'select-d' : start_ladder === 'd', 'select-e' : start_ladder === 'e'}" > 
          <div class="ladder-block">
            <div class="ladder-top">
              <div class="top-box"><mo-button @click="start_btn('a')" class="ns-btn-round" :class="{'on' : start_ladder === 'a'}">A</mo-button></div>  <!-- 선택표시 on -->
              <div class="top-box"><mo-button @click="start_btn('b')" class="ns-btn-round" :class="{'on' : start_ladder === 'b'}">B</mo-button></div>
              <div class="top-box"><mo-button @click="start_btn('c')" class="ns-btn-round" :class="{'on' : start_ladder === 'c'}">C</mo-button></div>
              <div class="top-box"><mo-button @click="start_btn('d')" class="ns-btn-round" :class="{'on' : start_ladder === 'd'}">D</mo-button></div>
              <div class="top-box"><mo-button @click="start_btn('e')" class="ns-btn-round" :class="{'on' : start_ladder === 'e'}">E</mo-button></div>
            </div>
            <div class="ladder-con">
              <div class="line-y l01" />
              <div class="line-y l02" />
              <div class="line-y l03" />
              <div class="line-y l04" />
              <div class="line-y l05" />
              <div class="line-x l01 t10" />
              <div class="line-x l01 t50" />
              <div class="line-x l01 t80" />
              <div class="line-x l02 t20" />
              <div class="line-x l02 t60" />
              <div class="line-x l03 t10" />
              <div class="line-x l03 t40" />
              <div class="line-x l03 t70" />
              <div class="line-x l04 t30" />
              <div class="line-x l04 t60" />
              <!-- gift05 animation -->
              <div class="start-line seleted-gift05 selected-a" :class="{'start': start_ladder === 'a', }">
                <div class="line-y start-01 l01 t00 b90" />
                <div class="line-y start-03 l02 t10 b80" />
                <div class="line-y start-05 l03 t20 b60" />
                <div class="line-y start-07 l04 t40 b40" />
                <div class="line-y start-09 l05 t60 b00" />
                <div class="line-x start-02 l01 t10" />
                <div class="line-x start-04 l02 t20" />
                <div class="line-x start-06 l03 t40" />
                <div class="line-x start-08 l04 t60" />
              </div>
              <div class="start-line seleted-gift05 selected-b" :class="{'start': start_ladder === 'b', }">
                <div class="line-y start-01 l02 t00 b90" />
                <div class="line-x start-02 l01 t10 right" />
                <div class="line-y start-03 l01 t10 b50" />
                <div class="line-x start-04 l01 t50" />
                <div class="line-y start-05 l02 t50 b40" />
                <div class="line-x start-06 l02 t60" />
                <div class="line-y start-07 l03 t60 b30" />
                <div class="line-x start-08 l03 t70" />
                <div class="line-y start-09 l04 t70 b00" />
              </div>
              <div class="start-line seleted-gift05 selected-c" :class="{'start': start_ladder === 'c', }">
                <div class="line-y start-01 l03 t00 b90" />
                <div class="line-x start-02 l03 t10" />
                <div class="line-y start-03 l04 t10 b70" />
                <div class="line-x start-04 l04 t30" />
                <div class="line-y start-05 l05 t30 b40" />
                <div class="line-x start-06 l04 t60 right" />
                <div class="line-y start-07 l04 t60 b30" />
                <div class="line-x start-08 l03 t70 right" />
                <div class="line-y start-09 l03 t70 b00" />
              </div>
              <div class="start-line seleted-gift05 selected-d" :class="{'start': start_ladder === 'd', }">
                <div class="line-y start-01 l04 t00 b90" />
                <div class="line-x start-02 l03 t10 right" />
                <div class="line-y start-03 l03 t10 b80" />
                <div class="line-x start-04 l02 t20 right" />
                <div class="line-y start-05 l02 t20 b50" />
                <div class="line-x start-06 l01 t50 right" />
                <div class="line-y start-07 l01 t50 b20" />
                <div class="line-x start-08 l01 t80 " />
                <div class="line-y start-09 l02 t80 b00" />
              </div>
              <div class="start-line seleted-gift05 selected-e" :class="{'start': start_ladder === 'e', }">
                <div class="line-y start-01 l05 t00 b70" />
                <div class="line-x start-02 l04 t30 right" />
                <div class="line-y start-03 l04 t30 b60" />
                <div class="line-x start-04 l03 t40 right" />
                <div class="line-y start-05 l03 t40 b40" />
                <div class="line-x start-06 l02 t60 right" />
                <div class="line-y start-07 l02 t60 b20" />
                <div class="line-x start-08 l01 t80 right" />
                <div class="line-y start-09 l01 t80 b00" />
              </div>
              <!-- gift04 animation -->
              <div class="start-line seleted-gift04 selected-a" :class="{'start': start_ladder === 'a', }">
                <div class="line-y start-01 l01 t00 b90" />
                <div class="line-y start-03 l02 t10 b80" />
                <div class="line-y start-05 l03 t20 b60" />
                <div class="line-x start-02 l01 t10" />
                <div class="line-x start-04 l02 t20" />
                <div class="line-x start-06 l03 t40" />
                <div class="line-y start-07 l04 t40 b30" />
                <div class="line-x start-08 l03 t70 right" />
                <div class="line-y start-09 l03 t70 b00" />
              </div>
              <div class="start-line seleted-gift04 selected-b" :class="{'start': start_ladder === 'b', }">
                <div class="line-y start-01 l02 t00 b90" />
                <div class="line-x start-02 l01 t10 right" />
                <div class="line-y start-03 l01 t10 b50" />
                <div class="line-x start-04 l01 t50" />
                <div class="line-y start-05 l02 t50 b40" />
                <div class="line-x start-06 l02 t60" />
                <div class="line-y start-07 l03 t60 b30" />
                <div class="line-x start-08 l03 t70" />
                <div class="line-y start-09 l04 t70 b00" />
              </div>
              <div class="start-line seleted-gift04 selected-c" :class="{'start': start_ladder === 'c', }">
                <div class="line-y start-01 l03 t00 b90" />
                <div class="line-x start-02 l03 t10" />
                <div class="line-y start-03 l04 t10 b60" />
                <div class="line-x start-04 l03 t40 right" />
                <div class="line-y start-05 l03 t40 b40" />
                <div class="line-x start-06 l02 t60 right" />
                <div class="line-y start-07 l02 t60 b20" />
                <div class="line-x start-08 l01 t80 right" />
                <div class="line-y start-09 l01 t80 b00" />
              </div>
              <div class="start-line seleted-gift04 selected-d" :class="{'start': start_ladder === 'd', }">
                <div class="line-y start-01 l04 t00 b90" />
                <div class="line-x start-02 l03 t10 right" />
                <div class="line-y start-03 l03 t10 b80" />
                <div class="line-x start-04 l02 t20 right" />
                <div class="line-y start-05 l02 t20 b50" />
                <div class="line-x start-06 l01 t50 right" />
                <div class="line-y start-07 l01 t50 b20" />
                <div class="line-x start-08 l01 t80 " />
                <div class="line-y start-09 l02 t80 b00" />
              </div>
              <!-- gift03 animation -->
              <div class="start-line seleted-gift03 selected-a" :class="{'start': start_ladder === 'a', }">
                <div class="line-y start-01 l01 t00 b90" />
                <div class="line-x start-02 l01 t10" />
                <div class="line-y start-03 l02 t10 b80" />
                <div class="line-x start-04 l02 t20" />
                <div class="line-y start-05 l03 t20 b40" />
                <div class="line-x start-06 l02 t60 right" />
                <div class="line-y start-07 l02 t60 b20" />
                <div class="line-x start-08 l01 t80 right" />
                <div class="line-y start-09 l01 t80 b00" />
              </div>
              <div class="start-line seleted-gift03 selected-b" :class="{'start': start_ladder === 'b', }">
                <div class="line-y start-01 l02 t00 b90" />
                <div class="line-x start-02 l01 t10 right" />
                <div class="line-y start-03 l01 t10 b50" />
                <div class="line-x start-04 l01 t50" />
                <div class="line-y start-05 l02 t50 b40" />
                <div class="line-x start-06 l02 t60" />
                <div class="line-y start-07 l03 t60 b00" />
              </div>
              <div class="start-line seleted-gift03 selected-c" :class="{'start': start_ladder === 'c', }">
                <div class="line-y start-01 l03 t00 b80" />
                <div class="line-x start-02 l02 t20 right" />
                <div class="line-y start-03 l02 t20 b50" />
                <div class="line-x start-04 l01 t50 right" />
                <div class="line-y start-05 l01 t50 b20" />
                <div class="line-x start-06 l01 t80 " />
                <div class="line-y start-07 l02 t80 b00" />
              </div>

            </div>
            <div class="ladder-bottom" :class="{'on': start_ladder === 'a', 'on': start_ladder === 'b', 'on': start_ladder === 'c', 'on': start_ladder === 'd', 'on': start_ladder === 'e', }">
              <div class="bottom-box"><div class="gfit-box"></div></div>  <!-- 선택표시 on -->
              <div class="bottom-box"><div class="gfit-box"></div></div>
              <div class="bottom-box"><div class="gfit-box"></div></div>
              <div class="bottom-box"><div class="gfit-box"></div></div>
              <div class="bottom-box"><div class="gfit-box"></div></div>
            </div>
          </div>    
          <div class="select-abc" v-if="start_ladder === ''">      
            <span class="fs14rem fwm">마음에 드는 알파벳을 선택해 주세요</span>
          </div>
        </div>

      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue"  name="닫기" :disabled="isBtnableYn" @click="fn_close">닫기</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import moment from 'moment'
  import DateUtil from '@/ui/ps/comm/PSDateUtil'
  import MSPSA033P from '@/ui/sa/MSPSA033P.vue'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA038P",
    screenId: "MSPSA038P",
    components: {
    },
    props: {
      pTemplBkgTypCd: {
        type: String,
        default: ''
      },
      pEvtInfo: {
        type: Object,
        default: {}
      },
      pCustInfo: {
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA038P')
      this.templBkgTypCd = this.pTemplBkgTypCd
      this.custInfo = this.pCustInfo
      this.showEvtDtl = this.pEvtInfo
      console.log('사다리 this.showEvtDtl', this.showEvtDtl)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isShow: false,
        start_ladder: '', // 사다리 start 기본값;
        set_ladder: 'a', // 사다리 당첨 값
        templBkgTypCd: '', // 사다리 갯수
        showEvtDtl: {},
        custInfo: {}, // 고객정보
        rsltInfo: {}, // 당첨결과        
        isBtnableYn: false, // 하단 닫기버튼 비활성화 여부
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      start_btn (str) {
        this.start_ladder = str
        this.isBtnableYn = true
        this.getStore('progress').dispatch('UPDATE', true)
        setTimeout(() => {
          this.getStore('progress').dispatch('UPDATE', false)
          this.fn_GetRslt()
        },3000)
        
      },
      /*******************************************************************************
      * Function명: fn_GetRslt
      * 설명: 당첨결과
      *******************************************************************************/
      fn_GetRslt () {
        let lv_Vm = this
        console.log('this.custInfo', this.custInfo)
        console.log('this.custInfo.celno>>', this.custInfo.celno)
        let pParams = {
          'mobslEvtId': this.showEvtDtl.mobslEvtId,
          'mobslEvtObjOrgTypCd': this.showEvtDtl.mobslEvtObjOrgTypCd,
          'mobslChnlCustId': this.custInfo.chnlCustId,
          'custNm': this.custInfo.custNm,
          'custCelno': this.custInfo.celno.replaceAll('-', ''),
          'mobslCustId': this.custInfo.custId
        }
        console.log('당첨결과 param>>', pParams)
        console.log('this.custInfo.custId', this.custInfo.custId === '' , this.custInfo.custId === ' ')

        let trnstId = 'txTSSSA37I1'
        let auth = 'I'


        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.rsltInfo = response.body
              console.log('당첨결과 확인>>', lv_Vm.rsltInfo)
              lv_Vm.fn_OpenMSPSA033P()
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      fn_tabIdx (str) {
        this.tab_index = str
        this.start_ladder=!this.start_ladder;
      },
      /*******************************************************************************
      * Function명: fn_OpenMSPSA037P
      * 설명: 당첨결과 팝업 오픈
      *******************************************************************************/
      fn_OpenMSPSA033P() {
      this.IsMSPSA033P = this.$bottomModal.open(MSPSA033P, {
        properties: {
          pRsltInfo: this.rsltInfo,
          pCustInfo: this.custInfo,
          pEvtInfo:this.showEvtDtl
        },
        listeners: {
          onPopupConfirm: () => {
            this.$emit('onPopupConfirm')
            this.$bottomModal.close(this.IsMSPSA033P)
          }
        }
      })
      },
      /*******************************************************************************
      * Function명: fn_close
      * 설명: 룰렛 팝업 닫기
      *******************************************************************************/
      fn_close() {
        this.$emit('onPopupConfirm')
      }
    }
  };
</script>
<style scoped>
</style>
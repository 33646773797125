/*
* 업무구분: 이벤트 상세
* 화면 명: MSPSA031P
* 화면 설명: 이벤트 상세
* 화면 접근권한:
*/
<template>
  <ur-page-container ref="MSPSA031P" class="msp" :title="showEvtDtl.mobslEvtNm" :show-title="true" type="subpage" :topButton="false" @on-header-left-click="fn_close">
     <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container alignV="start" componentid="" direction="column" class="ing-event" :class="evtList.length > 0 ? showEvtDtl.classes : ''"> <!-- 테마지정 winter / spring / summer / fall -->
        <div class="page-move">
          <mo-button @click="fn_MoveEvt('pre')" class="opercity-btn pre-arrow"><mo-icon icon="msp-arrow-down" /></mo-button>
          <mo-button @click="fn_MoveEvt('next')" class="opercity-btn next-arrow ml6"><mo-icon icon="msp-arrow-down" /></mo-button>
        </div>
        <div class="theme-bg">
          <div class="title-box">
            <span class="txt fs22rem">
              <template v-for="(data, index) in showEvtDtl.titles">
                {{ data }} <br :key="index"/>
              </template>
            </span>
            <mo-button class="opercity-btn mt10" @click="fn_MoveScrollTo()"><span class="opercity-txt fs17rem">이벤트 상세보기</span></mo-button>
          </div>
        </div>

        <!-- 슬라이딩 배너 영역 -->
        <div class="event-gift ns-swiper-main"> <!-- ns-swiper-main -->
          <mo-carousel :key="showEvtDtl.mobslEvtId" :number-of-page="showEvtDtl.giftCarouselCnt" height="auto" :swiper-option="swiperOption" :prevButton="false" :nextButton="false" class="gift-width">
            <template #1>
              <div class="swiper-item gift01">
                <div class="txt-block">
                  <span class="txt-type fs18rem fwb">{{ showEvtDtl.rnk1GiftNm }}</span>
                  <span class="fs16rem">1등 {{ showEvtDtl.rnk1GiftFgr }}명</span>
                </div>
                <div v-if="showEvtDtl.dtlImg1" class="gift-img">
                  <img  :src="showEvtDtl.dtlImg1" />
                </div>
              </div>
            </template>
            <template #2>
              <div class="swiper-item gift02">
                <div class="txt-block">
                  <span class="txt-type fs18rem fwb">{{ showEvtDtl.rnk2GiftNm }}</span>
                  <span class="fs16rem">2등 {{ showEvtDtl.rnk2GiftFgr }}명</span>
                </div>
                 <div v-if="showEvtDtl.dtlImg2" class="gift-img">
                   <img :src="showEvtDtl.dtlImg2" />
                 </div>
              </div>
            </template>
            <template #3>
              <div class="swiper-item gift03">
                <div class="txt-block">
                  <span class="txt-type fs18rem fwb">{{ showEvtDtl.rnk3GiftNm }}</span>
                  <span class="fs16rem">3등 {{ showEvtDtl.rnk3GiftFgr }}명</span>
                </div>
                 <div v-if="showEvtDtl.dtlImg3" class="gift-img">
                    <img :src="showEvtDtl.dtlImg3" />
                 </div>
              </div>
            </template>
            <template #4>
              <div class="swiper-item gift03">
                <div class="txt-block">
                  <span class="txt-type fs18rem fwb">{{ showEvtDtl.rnk4GiftNm }}</span>
                  <span class="fs16rem">4등 {{ showEvtDtl.rnk4GiftFgr }}명</span>
                </div>
                 <div v-if="showEvtDtl.dtlImg4" class="gift-img">
                    <img :src="showEvtDtl.dtlImg4" />
                 </div>
              </div>
            </template>
            <template #5>
              <div class="swiper-item gift03">
                <div class="txt-block">
                  <span class="txt-type fs18rem fwb">{{ showEvtDtl.rnk5GiftNm }}</span>
                  <span class="fs16rem">5등 {{ showEvtDtl.rnk5GiftFgr }}명</span>
                </div>
                 <div v-if="showEvtDtl.dtlImg5" class="gift-img">
                    <img :src="showEvtDtl.dtlImg5" />
                 </div>
              </div>
            </template>
          </mo-carousel>
        </div>
        <!-- 슬라이딩 배너 영역 //-->

        <ur-box-container alignV="start" componentid="" direction="column" class="event-detail" >
        <!-- <div class="event-detail" ref="detail"> -->
          <div class="detail-title">
            <span  class="fs18rem fwm">
              <template v-for="(data,j) in showEvtDtl.titleDtl">
                  {{data}} <br :key="j"/>
              </template>
            </span>
          </div>
          <div class="detail-box mt20 w100" :class="isAlert ? 'mb200' : ''">
            <div class="detail-txt">
              <div class="fs18rem fwm">{{ showEvtDtl.mobslEvtDtlItmNm01 }}</div>
              <span class="fs14rem mt10">{{ showEvtDtl.mobslEvtDtlCntnt01 }}</span>
            </div>
            <div class="detail-txt" ref="detail" tabindex="-1">
              <div class="fs18rem fwm">{{ showEvtDtl.mobslEvtDtlItmNm02 }}</div>
              <span v-for="(data, index) in showEvtDtl.cntnt02s" class="fs14rem mt10" :key="index">
                {{ data }} <br>
              </span>
            </div>
          </div>
        <!-- </div> -->
        </ur-box-container>
      </ur-box-container>
    </ur-box-container>

    <!-- 팝업01: 이벤트 참여기회를 전부 사용 -->
    <mo-bottom-sheet ref="nsbottomsheet1" preventTouchClose class="ns-bottom-sheet msp-bottomSt-alert dsN-background">
    <!-- <mo-bottom-sheet ref="nsbottomsheet1" v-if="showEvtDtl.ptptScCd === '1'" class="ns-bottom-sheet msp-bottomSt-alert"> -->
      <div class="customer-info-txt txt-center">
        <p class="ment">이벤트 참여 기회를<br/> 전부 사용하셨습니다.</p>
      </div>

      <!-- <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="close1" name="고객등록동의">고객등록동의</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue"  name="고객검색">고객검색</mo-button>
          </div>
        </ur-box-container>
      </template> -->
    </mo-bottom-sheet>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_GoMSPCM050M" name="고객등록동의">고객등록동의</mo-button>
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SearchCustumPop"  name="고객검색">고객검색</mo-button>
     <!-- <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" disabled name="고객검색">본 이벤트 대상 고객이 아닙니다.</mo-button>  -->
      </div>
    </ur-box-container>

    <!-- 팝업02: ***님 다시검색 / 이벤트 참여 완료 -->
    <mo-bottom-sheet ref="nsbottomsheet2" preventTouchClose class="ns-bottom-sheet msp-bottomSt-alert dsN-background">
      <div class="customer-info-txt">
        <div class="ment fexTy3">
          <div class="fexTy5 flex-1">
            <span v-if="custInfo.custNm" class="fs19rem fwb txtSkip">{{ custInfo.custNm }}</span>
            <span class="fs19rem fwb minWmax ml8">님</span>
          </div> 
          <mo-button class="ns-btn-round white msp-btn h40 fs16rem ml10" @click="close2"><mo-icon icon="msp-refresh" class="mr4" />다시검색</mo-button>
        </div>
        <span v-if="custInfo.ptptScCd === '2'" class="dsD mt8 fs17rem">환영합니다.</span>
        <span v-else-if="custInfo.ptptScCd === '3'" class="dsD mt8 fs17rem">이미 이벤트를 참여하셨습니다.</span>
      </div>
      <template v-if="custInfo.rsltCd === 'A'" v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" disabled @click="close2" name="고객검색">본 이벤트 대상고객이 아닙니다</mo-button>
          </div>
        </ur-box-container>
      </template>
      <template v-else-if="custInfo.ptptScCd === '3'" v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" disabled @click="close2" name="고객검색">이벤트 참여 완료</mo-button>
          </div>
        </ur-box-container>
      </template>
      <template v-else-if="custInfo.ptptScCd === '2'" v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div class="relative-div">
            <mo-button color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_GoEvt" name="고객검색">이벤트 참여</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>


    <!-- 팝업03: 마케팅 동의 & 필수컨설팅 동의 후 참여 가능 -->
    <!-- <mo-bottom-sheet ref="nsbottomsheet3" class="ns-bottom-sheet msp-bottomSt-alert"> -->
    <mo-bottom-sheet ref="nsbottomsheet3" class="ns-bottom-sheet msp-bottomSt-alert" :close-btn="true" @closed="fn_NoAgreeClose">
      <div class="customer-info-txt txt-center">
        <p class="ment" v-html="bottomMessage"></p>
      </div>

      <template v-slot:action>
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative-bs">
          <div v-if="(showEvtDtl.mcnstCnsntCondYn === 'Y' && custInfo.mndtCnstgCnsntYn === 'N') || //필컨 Y 조건 이벤인데 필컨 N
          (showEvtDtl.newCnsntYn === 'Y' && custInfo.newMndtCnstgCnsntYn === 'N') || //신규필컨 Y조건 이벤인데 신규필컨 N
          custInfo.mktCnsntYn === 'N'" class="relative-div"> 
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_GoMSPCM050M">고객등록동의</mo-button>
          </div>
          <div v-else-if="custInfo.celno === ' '" class="relative-div">
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_GoMSPCM270M">고객카드수정</mo-button>
          </div>
        </ur-box-container>
      </template>
    </mo-bottom-sheet>

    
    <!-- 토스트 -->
    <mo-snackbar ref="stackableSnackbar" stackable :duration="30000" />
  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import {createScrollAnimateInstance} from "@/utils/scroll/scroll-animate.js"
  import Msg from '@/systems/webkit/msg/msg'
  import MSPSA037P from '@/ui/sa/MSPSA037P.vue'
  import MSPSA038P from '@/ui/sa/MSPSA038P.vue'
  import MSPCM152P from '@/ui/cm/MSPCM152P' // 고객선택
  import MSPCM300P from '@/ui/cm/MSPCM300P' // 고객카드
  import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
  import moment from 'moment'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA031P",
    screenId: "MSPSA031P",
    isBackKeyHandle: true,
    components: {
    },
    props: {
      evtList: {
        type: Array,
        default: []
      },
      mobslEvtId: {
        type: String,
        default: ''
      },
      pCustInfo: {
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      console.log('evtList>>>>', this.evtList)
      console.log('mobslEvtId>>>>', this.mobslEvtId)
      console.log('pCustInfo>>>', this.pCustInfo)
      window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_deviceBack)
      if (Object.keys(this.pCustInfo).length > 0) {
        this.custInfo = this.pCustInfo
        console.log('캠페인 고객화면 -> 고객정보>>', this.custInfo)
        console.log('캠페인 고객화면 -> 캠페인아이디>>', this.mobslEvtId)
        console.log('캠페인 이벤트 상세 시작')
        this.fn_retvCmpgnEvt(this.mobslEvtId)
      } else {
        console.log('일반 이벤트 상세 시작!')
        this.dtlEvtList = this.evtList
        this.fn_OnLoad()
      }
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA031P')
      // 스크롤
      //this.scrollAnimate = createScrollAnimateInstance(this.$refs.MSPSA031P, 'y')
      this.scrollAnimate = createScrollAnimateInstance(this.$refs.detail, 'y')
      console.log('mounted 콘솔로그', this.$refs.nsbottomsheet1)
      this.fn_ChkEvtptpt()
    },
    destroyed() {
      window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_deviceBack)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        scrollAnimate: null,
        //isScroll: '',
        infoPrcusCnsntTypCd:'', // 조회 정보활용동의 유형코드( 17:필컨, 18:필컨,마케팅, 19:마케팅)
        ingEvtIdx: 0,
        isAlert: false, // 고객검색 후 공간 확보 위해
        showEvtDtl: {},
        dtlEvtList: [],
        showEvtDtlIdx: 0,
        giftCarouselCnt: 0, // 상품 스와이프 노출 갯수
        IsMSPSA203P: {},
        IsMSPSA037P: {},
        IsMSPSA038P: {},
        IsMSPCM300P: {},
        cmpgnCnslt: {},
        bottomMessage: '',
        isFirst: true, // 고객참여형 이벤트 최초 접속 여부
        slctEvtId: '', // 선택한 이벤트ID
        custInfo: { // 검색해온 고객 정보
          age: 0,
          telno: '',
          chnlCstgrId: '',
          chnlCustId: '',
          chnlCustScCd: '',
          custNm: '',
          isExistCustYN: '',
          knb: '',
          mnCustRltnCd: '',
          rrnFrnoNo: '',
          unAprvCustYn: '',
        }, 
        swiperOption: {
            centerSlides: true,
            slidesPerView: 1,
            spaceBetween: 20,
            autoplay: {
              delay: 5000,
              disableOnInteraction: false, 
            },
          },
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      /*******************************************************************************
      * Function명: fn_ChkEvtptpt
      * 설명: 이벤트 참가가능여부 확인
      *******************************************************************************/
      fn_ChkEvtptpt() {
        if (this.showEvtDtl.ptptScCd === '1') {
          this.$refs.nsbottomsheet1.open()
        }
      },
      /*******************************************************************************
      * Function명: fn_MoveScrollTo
      * 설명: 상세보기 버튼 클릭시 상세내용 포커스 이동
      *******************************************************************************/
      fn_MoveScrollTo() {
        console.log('this.$refs.detail', this.$refs.detail)
        this.$refs.detail.focus()
        console.log('스크롤 이동 함수!!')
      },
      fn_ClickChange () {
        if(this.value2)this.value2 = false;
        else this.value2 = true;
      },
      ontost(){
        this.$refs.stackableSnackbar.show({text:'삭제되었습니다.'});
        this.$refs.nsbottomsheet1.close();
      },
      open1() { this.$refs.nsbottomsheet1.open();  },
      close1() { this.$refs.nsbottomsheet1.close(); },
      open2() { this.$refs.nsbottomsheet2.open();  },
      close2() { 
        this.fn_NoAgreeClose()
        this.$refs.nsbottomsheet2.close();
      },
      open3() { this.$refs.nsbottomsheet3.open();  },
      close3() { this.$refs.nsbottomsheet3.close(); },

      fn_NoAgreeClose() {
        this.custInfo = { // 고객 정보 초기화
          age: 0,
          telno: '',
          chnlCstgrId: '',
          chnlCustId: '',
          chnlCustScCd: '',
          custNm: '',
          isExistCustYN: '',
          knb: '',
          mnCustRltnCd: '',
          rrnFrnoNo: '',
          unAprvCustYn: '',
        }
        this.isAlert = false
      },

      fn_close() {
        this.$options.isBackKeyHandle = false
        this.$emit('onPopupConfirm')
      },

      /*********************************************************
       * Function명: fn_deviceBack
       * 설명: 안드로이드 백키 설정
       *********************************************************/
      fn_deviceBack() {
        this.$refs.nsbottomsheet1.close()
        this.$refs.nsbottomsheet2.close()
        console.log('031P 안드로이드백')
        this.fn_close()
      },

      /*********************************************************
       * Function명: fn_retvCmpgnEvt
       * 설명: 캠페인이벤트 정보 조회
       *********************************************************/
      fn_retvCmpgnEvt(pCmpgnId) {
        let lv_Vm = this
        let cmpnId = {
          mobslEvtID: pCmpgnId
        }
        let cmpnIdList = []
        cmpnIdList.push(cmpnId)
        let pParams = {
          cmpgnEvtIdList: cmpnIdList
        }

        let trnstId = 'txTSSSA31S2'
        let auth = 'S'
        console.log('cmpgnEvtIdList>>>', pParams)

        lv_Vm.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
            if (response.body !== null && response.body !== '') {
              console.log('캠페인이벤트 응답값>>>', response.body.prgEvtList)
              lv_Vm.dtlEvtList = response.body.prgEvtList
              lv_Vm.fn_OnLoad()
            }
        })
              
      },

      /*********************************************************
       * Function명: fn_BottomAlert
       * 설명: 알럿 오픈
       *********************************************************/
      fn_BottomAlert (contents) {
        this.bottomAlert = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: '',
          content: contents,
        },
        listeners: {
          onPopupConfirm: () => {
            this.$bottomModal.close(this.bottomAlert);
          },
          onPopupClose: () => {
            this.$bottomModal.close(this.bottomAlert);
            }
          }
        })
      },

      /*******************************************************************************
      * Function명: fn_OnLoad
      * 설명: 초기 데이터 셋팅
      *******************************************************************************/
      fn_OnLoad() {
        let lv_vm = this
        console.log('this.mobslEvtId>>>', this.mobslEvtId)
        this.fn_setEventId()
        this.dtlEvtList.forEach(async(e, idx) => {
          lv_vm.giftCarouselCnt = 0
          switch(e.dgTypeCd) {
            case '1':
              e.classes = 'spring'
              break
            case '2':
              e.classes = 'summer'
              break
            case '3':
              e.classes = 'fall'
              break
            case '4':
              e.classes = 'winter'
              break
          }
          e.flag = e.mobslEvtId.substring(0, 1) // 고객참여형 이벤트 구분값(0:기존이벤트, 1,2: 고객참여형(업로드형))
          e.titles = e.mobslEvtTitlNm.split('\n')
          e.titleDtl = e.mobslEvtExpln.split('\n')
          e.cntnt02s = e.mobslEvtDtlCntnt02.split('\n')
          if (e.rnk1GiftNm !== null) {lv_vm.giftCarouselCnt++}
          if (e.rnk2GiftNm !== null) {lv_vm.giftCarouselCnt++}
          if (e.rnk3GiftNm !== null) {lv_vm.giftCarouselCnt++}
          if (e.rnk4GiftNm !== null) {lv_vm.giftCarouselCnt++}
          if (e.rnk5GiftNm !== null) {lv_vm.giftCarouselCnt++}
          e.giftCarouselCnt = lv_vm.giftCarouselCnt
          
          console.log('이미지 변환 시작', idx)
          if (e.rnk1GiftImgUrl !== null) {
            let t_FileNmDotPos = e.rnk1GiftImgUrl.indexOf('.')
            let t_ImgType = e.rnk1GiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : e.rnk1GiftImgUrl.substring(t_FileNmDotPos + 1)
            let t_FileNm = e.rnk1GiftImgUrl.substring(t_FileNmDotPos - 11)

            let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
            e.dtlImg1 = await lv_vm.$bizUtil.httpUrlCall(strUrl)
          }
          if (e.rnk2GiftImgUrl !== null) {
            let t_FileNmDotPos = e.rnk2GiftImgUrl.indexOf('.')
            let t_ImgType = e.rnk2GiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : e.rnk2GiftImgUrl.substring(t_FileNmDotPos + 1)
            let t_FileNm = e.rnk2GiftImgUrl.substring(t_FileNmDotPos - 11)

            let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
            e.dtlImg2 = await lv_vm.$bizUtil.httpUrlCall(strUrl)
          }
          if (e.rnk3GiftImgUrl !== null) {
            console.log('3333')
            let t_FileNmDotPos = e.rnk3GiftImgUrl.indexOf('.')
            let t_ImgType = e.rnk3GiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : e.rnk3GiftImgUrl.substring(t_FileNmDotPos + 1)
            let t_FileNm = e.rnk3GiftImgUrl.substring(t_FileNmDotPos - 11)

            let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
            e.dtlImg3 = await lv_vm.$bizUtil.httpUrlCall(strUrl)
          }
          if (e.rnk4GiftImgUrl !== null) {
            let t_FileNmDotPos = e.rnk4GiftImgUrl.indexOf('.')
            let t_ImgType = e.rnk4GiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : e.rnk4GiftImgUrl.substring(t_FileNmDotPos + 1)
            let t_FileNm = e.rnk4GiftImgUrl.substring(t_FileNmDotPos - 11)

            let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
            e.dtlImg4 =  await lv_vm.$bizUtil.httpUrlCall(strUrl)
          }
          if (e.rnk5GiftImgUrl !== null) {
            let t_FileNmDotPos = e.rnk5GiftImgUrl.indexOf('.')
            let t_ImgType = e.rnk5GiftImgUrl.substring(t_FileNmDotPos + 1) === 'jpg' ? 'jpeg' : e.rnk5GiftImgUrl.substring(t_FileNmDotPos + 1)
            let t_FileNm = e.rnk5GiftImgUrl.substring(t_FileNmDotPos - 11)

            let strUrl = '/filedown.do?path=EVT_WIN_GIFT_IMG_DIR&filename=' + t_FileNm + '&type=' + t_ImgType
            e.dtlImg5 = await lv_vm.$bizUtil.httpUrlCall(strUrl)
          }
          console.log('이미지 변환 완료', idx)
          lv_vm.$nextTick(()=> {
            if (e.mobslEvtId === lv_vm.mobslEvtId) {
              console.log('위치?', idx)
              lv_vm.showEvtDtlIdx = idx
              lv_vm.showEvtDtl = e

              if (lv_vm.showEvtDtl.mobslEvtTypCd === '' || lv_vm.showEvtDtl.mobslEvtTypCd === null || lv_vm.showEvtDtl.mobslEvtTypCd === undefined) {
                if (lv_vm.showEvtDtl.flag === '1') {
                  lv_vm.showEvtDtl.mobslEvtTypCd = 'A'
                } else if (lv_vm.showEvtDtl.flag === '2') {
                  lv_vm.showEvtDtl.mobslEvtTypCd = 'B'
                } else if (lv_vm.showEvtDtl.flag === '3') {
                  lv_vm.showEvtDtl.mobslEvtTypCd = 'C'
                }
              }

              if (lv_vm.showEvtDtl.fcCustPtptRstcNts === lv_vm.showEvtDtl.ptpFgr) {
                lv_vm.showEvtDtl.ptptScCd = '1' // 참여기회 전부 사용
              } else {
                lv_vm.showEvtDtl.ptptScCd = '2' // 참여 가능
              }
              lv_vm.slctEvtId = lv_vm.showEvtDtl.mobslEvtId
              console.log('showEvtDtl>>>', lv_vm.showEvtDtl)
              if (lv_vm.showEvtDtl.mobslEvtTypCd === 'A' || lv_vm.showEvtDtl.mobslEvtTypCd === 'C' || lv_vm.showEvtDtl.flag === '1') {
                lv_vm.fn_selCmpgnEvtTgt()
              }
            }
          })
        })
        console.log('this.dtlEvtList>>>', this.dtlEvtList)
    },

      /******************************************************************************
      * Function명 : fn_setEventId
      * 설명       : 이벤트 최초 진입 시 팝업을 위해, 접근 이벤트ID localstorage에 저장하는 함수
      ******************************************************************************/
      fn_setEventId () {
        let eventId = ''
        if (localStorage.getItem('READ_NOTICE_EVENTID') !== undefined && localStorage.getItem('READ_NOTICE_EVENTID') !== null) {
          eventId = localStorage.getItem('READ_NOTICE_EVENTID')
        }
        this.dtlEvtList.forEach(e => {
          eventId += e.mobslEvtId + '|'
        })
        console.log('저장된 ID리스트>>>', eventId)
        localStorage.setItem('READ_NOTICE_EVENTID', eventId)
        this.fn_getEventId()
      },
      /******************************************************************************
      * Function명 : fn_getEventId
      * 설명       : 이벤트 접근 이력 확인하여 공지팝업 띄워주는 함수
      ******************************************************************************/
      fn_getEventId () {
        console.log('접근이력 확인')
        let eventIds = localStorage.getItem('READ_NOTICE_EVENTID')
        this.isFirst = true // 최초접근여부 초기화

        if (eventIds !== null) {
          let idArray = String(eventIds).split('|')

          idArray.forEach(id => {
              if (id === this.showEvtDtl.mobslEvtId) {
                this.isFirst = false
              }
          })
        }

        if (this.isFirst && String(this.showEvtDtl.mobslEvtId).substring(0, 1) === '1') {
          this.fn_BottomAlert(`'캠페인고객'메뉴에서 이벤트 고객을 확인하세요.`)
        }
      },
      /*******************************************************************************
      * Function명: fn_MoveEvt
      * 설명: 좌우 이벤트 이동
      *******************************************************************************/
      fn_MoveEvt(param) {
        console.log('이벤트 이동 클릭>>>', param)
        if (param === 'next') {
          if (this.showEvtDtlIdx !== this.dtlEvtList.length - 1) {
            this.showEvtDtlIdx++
            this.showEvtDtl = this.dtlEvtList[this.showEvtDtlIdx]
          } else {
          this.showEvtDtlIdx = 0
          this.showEvtDtl = this.dtlEvtList[this.showEvtDtlIdx]
        }
        } else {
          if (this.showEvtDtlIdx !== 0) {
            this.showEvtDtlIdx--
            this.showEvtDtl = this.dtlEvtList[this.showEvtDtlIdx]
          } else {
            this.showEvtDtlIdx = this.dtlEvtList.length - 1
            this.showEvtDtl = this.dtlEvtList[this.showEvtDtlIdx]
          }
        }
        this.slctEvtId = this.showEvtDtl.mobslEvtId
        console.log('이동쪽 this.custInfo', this.custInfo.custNm)
        this.fn_getEventId()
        if (this.custInfo.custNm.length !== 0) {
          this.fn_dtlCustInfo()
        }
        //this.fn_GetCnsntInfo(this.custInfo)
      },
      /*******************************************************************************
      * Function명: fn_selCmpgnEvtTgt
      * 설명: 캠페인 이벤트 참여 대상 조회
      *******************************************************************************/
      fn_selCmpgnEvtTgt () {
        console.log('캠페인 이벤트 참여대상 조회 시작!!')
        let lv_Vm = this
        let pParams = {
          'cnsltNo': lv_Vm.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
          'mobslCustID': lv_Vm.custInfo.custId,
          'mobslEvtID': lv_Vm.slctEvtId,
          'mobslChnlCustID': lv_Vm.custInfo.chnlCustId,
          'mobslEvtTypCD': lv_Vm.showEvtDtl.mobslEvtTypCd
        }
        console.log('캠페인이벤트 타입코드 추가확인>>', pParams)
        let auth = 'S'
        let trnstId = 'txTSSSA33S2' // 캠패인 이벤트 참여 대상 조회 [F1CRM0182]

        // 캠패인 이벤트 참여 대상 조회
        let SACmpgnEvtTgtSVO = {}

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            console.log('캠페인 이벤트 참여대상 조회 완료!>>', response)
            if (response.body !== null && response.body !== '') {
              SACmpgnEvtTgtSVO = response.body
              lv_Vm.custInfo.rsltCd = SACmpgnEvtTgtSVO.rsltCd
              lv_Vm.custInfo.rsltMsgVal = SACmpgnEvtTgtSVO.rsltMsgVal
              lv_Vm.$forceUpdate()
              console.log('lv_Vm.custInfo: ', lv_Vm.custInfo)
              console.log('rsltCd: ', lv_Vm.custInfo.rsltCd)
              console.log('rsltMsgVal: ', lv_Vm.custInfo.rsltMsgVal)

              if (lv_Vm.custInfo.rsltCd === 'S') { // 참여대상
                // 정보활용동의고객상세조회
                lv_Vm.fn_dtlCustInfo()
              } else if (lv_Vm.custInfo.rsltCd === 'A') {
                lv_Vm.$refs.nsbottomsheet2.open()
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.custInfo.rsltMsgVal)
              }
            } else {
              // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
            // 시스템적인 오류가 발생하면, 일반적인 에러 메세지를 confirm으로 표시
            lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('CM')['ECOU011'])
          })
      },

      /*******************************************************************************
      * Function명: fn_dtlCustInfo
      * 설명: 고객상세인적사항 조회
      *******************************************************************************/
      fn_dtlCustInfo () {
        let lv_Vm = this
        let pParams = {
          'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userId,
          'chnlCustId': this.custInfo.chnlCustId
        }
        let auth = 'S'
        let trnstId = 'txTSSCM11S9' // 고객상세인적사항 조회 [IF-CM-0015: F1MDI0270]
        console.log('고객상세인적사항 조회>>', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {
          if (response.body !== null && response.body !== '') {
            lv_Vm.custInfo.celno = response.body.celno
            lv_Vm.custInfo.mktCnsntYn = response.body.mktPrcusCnsntYn
            lv_Vm.custInfo.mndtCnstgCnsntYn = response.body.mndtCnstgCnsntYn
            lv_Vm.custInfo.chnlCstgrId = response.body.chnlCstgrId
            lv_Vm.custInfo.custId = response.body.custId
            lv_Vm.fn_GetCnsntInfoDtl()
            //lv_Vm.fn_GetCnsntInfo(lv_Vm.custInfo)
          } else {
            // 서비스에서 에러 코드를 던지면, 해당 메세지를 confirm 창을 통해 표시
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
      },
      /*******************************************************************************
      * Function명: fn_GoMSPCM050M
      * 설명: 고객등록 동의 화면 이동
      *******************************************************************************/
      fn_GoMSPCM050M() {
        this.$emit('goMspCm050m', this.custInfo.custNm)
      },
      /*******************************************************************************
      * Function명: fn_GoMSPCM270M
      * 설명: 고객카드 수정 화면 이동
      *******************************************************************************/
      fn_GoMSPCM270M() {
        //this.$emit('goMspCm270m', this.custInfo.chnlCustId)
        this.fn_OpenMSPCM300P(this.custInfo.chnlCustId)
      },

      /*******************************************************************************
      * Function명: fn_OpenMSPCM300P
      * 설명: 고객카드 수정 팝업 오픈
      *******************************************************************************/
      fn_OpenMSPCM300P (param) {
        this.IsMSPCM300P = this.$bottomModal.open(MSPCM300P, {
          properties: {
            pChnlCustId: param,
            pCnsltNo: this.getStore('userInfo').getters.getUserInfo.userId
          },
          listeners: {
            onPopupConfirm: (data) => {
              console.log('고객카드 수정완료!!', data)
              // 고객카드 수정 알림 팝업 종료
              this.$refs.nsbottomsheet3.close()

              // 고객카드 등록 완료 시에도 고객 재조회 없이, 고객검색 재진행 (*취소 시 콜백 구현 제외 위해)
              // this.fn_GetCnsntInfo(this.custInfo)
              this.$bottomModal.close(this.IsMSPCM300P);
            },
            onPopupClose: () => {              
              this.$bottomModal.close(this.IsMSPCM300P);
            }
          }
        },
        {
          properties: {
            noHeader: true
          }
        })
      },

      /*******************************************************************************
      * Function명: fn_SearchCustumPop
      * 설명: 고객검색팝업 오픈
      *******************************************************************************/
      fn_SearchCustumPop() {
        console.log('fn_SearchCustumPop')
        //this.$refs.nsbottomsheet2.close()
        this.IsMSPCM152P = this.$bottomModal.open(MSPCM152P, {
          properties: {
            pChnlCustIds : [
              { targetType: 'health',
                parentsViewId: 'MSPSA031P', 
                custList: []
              }
            ]
          },
          listeners: {
            onNextService: (pData) => {
              this.custInfo = pData.selectList[0]
              console.log('고객선택 완료>>', this.custInfo)
              //this.fn_getCelNo() // 핸드폰 번호 재조회
              if (this.showEvtDtl.mobslEvtTypCd === 'A' || this.showEvtDtl.mobslEvtTypCd === 'C' || String(this.showEvtDtl.mobslEvtId).substring(0, 1) === '1') { // 캠페인 참여대상 확인
                this.fn_selCmpgnEvtTgt()
              } else {
                this.fn_dtlCustInfo()
                //this.fn_GetCnsntInfo(this.custInfo)
              }
              this.$bottomModal.close(this.IsMSPCM152P)
            },
            onPopupCancel: () => {
              console.log('onPopupCancel call')
              this.$bottomModal.close(this.IsMSPCM152P)
            },
            onPopupClose: () => {
              console.log('onPopupClose call')
              this.$bottomModal.close(this.IsMSPCM152P)
            }
          }
        })
      },

      /*******************************************************************************  
      * Function명: fn_getCelNo
      * 설명: 마스킹 없는 핸드폰번호 조회
      *******************************************************************************/
      /* fn_getCelNo() {
      let lv_Vm = this
      let pParams = {}

      const trnstId = 'txTSSCM31S1'
      const auth = 'S'

      // 필수 Input 셋팅
      pParams.chnlCustId = lv_Vm.custInfo.chnlCustId // 채널고객아이디
      pParams.cnsltNo = lv_Vm.getStore('userInfo').getters.getUserInfo.userEno, // 컨설턴트사번
      pParams.whlInqrYn = 'Y' // 전체조회여부 - 디폴트
      pParams.conctScCd = '1' // 접속구분코드 5 (1.내부(INTRANET) 2.외부(INTERNET) ) (As-is) MobileHeaderManager.getCommVO().conctScCd;
      console.log('마스킹 없은 폰번호 조회>>', lv_Vm.getStore('userInfo').getters.getUserInfo)
      this.post(lv_Vm, pParams, trnstId, auth).then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null && response.body !== '') {
            lv_Vm.custInfo.celno = response.body.cMCustCardBasInfoSVO.celno
            }
        })
      }, */

      /*******************************************************************************
      * Function명: fn_GoEvt
      * 설명: 사다리, 룰렛 이벤트 팝업 이동
      *******************************************************************************/
      fn_GoEvt() {
        if (this.showEvtDtl.templTypCd === '1') { // 룰렛
          this.fn_OpenMSPSA037P()
        } else { // 사다리
          this.fn_OpenMSPSA038P()
        }
      },

      /*******************************************************************************
      * Function명: fn_OpenMSPSA037P
      * 설명: 룰렛이벤트 팝업
      *******************************************************************************/
      fn_OpenMSPSA037P() {
        this.IsMSPSA037P = this.$bottomModal.open(MSPSA037P, {
                    properties: {
                      pTemplBkgTypCd: this.showEvtDtl.templBkgTypCd,
                      pShowEvtDtl: this.showEvtDtl,
                      pCustInfo: this.custInfo
                    },
                    listeners: {
                      onPopupConfirm: () => {
                        console.log('onPopupConfirm call')
                        this.fn_GetCnsntInfo(this.custInfo)
                        this.$bottomModal.close(this.IsMSPSA037P)
                      },
                    },
                  },
                  {
                    properties: {
                      noHeader: true
                    }
                  })
      },
      /*******************************************************************************
      * Function명: fn_OpenMSPSA038P
      * 설명: 사다리 이벤트 팝업
      *******************************************************************************/
      fn_OpenMSPSA038P() {
        this.IsMSPSA038P = this.$bottomModal.open(MSPSA038P, {
                    properties: {
                      pTemplBkgTypCd: this.showEvtDtl.templBkgTypCd,
                      pEvtInfo: this.showEvtDtl,
                      pCustInfo: this.custInfo
                    },
                    listeners: {
                      onPopupConfirm: () => {
                        console.log('onPopupConfirm call')
                        this.fn_GetCnsntInfo(this.custInfo)
                        this.$bottomModal.close(this.IsMSPSA038P)
                      },
                    },
                  },
                  {
                    properties: {
                      noHeader: true
                    }
                  })
      },

      /*******************************************************************************
      * Function명: fn_GetCnsntInfoDtl
      * 설명: 동의정보 조회
      *******************************************************************************/
      fn_GetCnsntInfoDtl () {
        // 신규동의여부 ASR230500413 [사랑On] 이벤트(룰렛사다리) 내용 변경 요청
        let t_newCnsntYn = this.showEvtDtl.newCnsntYn
        console.log('t_newCnsntYn>>>', this.showEvtDtl.newCnsntYn)

        let pParams = {
          'cnsltNo': this.getStore('userInfo').getters.getUserInfo.userEno,
          'custNm': this.custInfo.custNm, // 고객명
          'chnlCustId': this.custInfo.chnlCustId,
          'chnlCstgrId': this.custInfo.chnlCstgrId // 채널고객그룹ID
        }

        let trnstId = 'txTSSCM10S5' // 정보활용동의고객상세조회
        let auth = 'S'

        let lv_Vm = this
        console.log('정보활용동의고객 상세조회>>', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body.iCCustInfoPrcusCnsntVO !== null) {
              let t_CnsntList = response.body.iCCustInfoPrcusCnsntVO

              // 본인 항목만 추출
              t_CnsntList.some(
                (item) => {
                  if (item.chnlCustId === lv_Vm.custInfo.chnlCustId) {
                    lv_Vm.custInfo.mndtCnstgCnsntStrYmd = item.infoPrcusCnsntYmd13 // 필수컨설팅동의시작일자
                    console.log('mndtCnstgCnsntStrYmd>>>>', item.infoPrcusCnsntYmd13)
                    return (item.chnlCustId === lv_Vm.custInfo.chnlCustId)
                  }
                }
              )
              // ASR230500413 [사랑On] 이벤트(룰렛사다리) 내용 변경 요청 : 신규동의여부 체크 시, 고객카드 조회
              if (t_newCnsntYn === 'Y') {
                console.log('신규동의 체크 !!!', t_newCnsntYn)
                lv_Vm.fn_GetCustCardInfo()
              } else {
                lv_Vm.fn_GetCnsntInfo(lv_Vm.custInfo)
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.lv_IsLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },

      /*******************************************************************************
      * ASR230500413 [사랑On] 이벤트(룰렛사다리) 내용 변경 요청
      * Function명: fn_GetCustCardInfo
      * 설명: 고객카드 조회
      *******************************************************************************/
      fn_GetCustCardInfo () {
        let pParams = {
          prcsfCd: '01', // 01메인고객 02 세대원 // 필수
          custNm: this.custInfo.custNm, // 고객명 // 필수
          knbFrno: '',
          knbBknoCipher: '',
          knbBkno: '',
          cnsltNo: this.getStore('userInfo').getters.getUserInfo.userEno, // 필수
          celno: '',
          chnlCustId: this.custInfo.chnlCustId,
          infoPrcusCnsntTypCd: '18' // 정보활용동의 유형 - 17 : 필동 , 18 : 필동+마동 , 19 : 마동 // 필수
        }
        let trnstId = 'txTSSCM13S1' // 정보활용동의고객상세조회 [F1MDI0372]
        let auth = 'S'

        let lv_Vm = this
        console.log('정보활용동의 상세조회>>', pParams)
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null) {
              let custCardRegMdfcVO = response.body
              //let tmpChnlCustId = custCardRegMdfcVO.iCHoshInfoListInqrVO[0].chnlCustId
              lv_Vm.custInfo.crdat = custCardRegMdfcVO.iCHoshInfoListInqrVO[0].crdat

              /*
                [ASR240300384][사랑On 3.0_활동] [사랑On] 구 사랑온 전체이벤트 시스템 연결 개선
                (기존) 고객카드 생성년월 = 필수컨설팅동의유효년월
                (변경)1. 고객카드 생성년월 = 이벤트 필수동의받은 시작기간년월
                        - false : 이벤트 참여조건에 맞지 않습니다.
                      2. 이벤트 필수동의받은기간 내 필수컨설팅동의유효시작 날짜 체크
              */                            
              let isNewCust = false              
              if (custCardRegMdfcVO?.iCHoshInfoListInqrVO && custCardRegMdfcVO.iCHoshInfoListInqrVO.length > 0 && lv_Vm.showEvtDtl?.mcnstCnsntStrDtm) {
                isNewCust = custCardRegMdfcVO.iCHoshInfoListInqrVO[0].crdat.substring(0, 6) === lv_Vm.showEvtDtl.mcnstCnsntStrDtm.substring(0, 6) ? true : false
              }
              let isRangeCheck = false
              if (lv_Vm.custInfo?.mndtCnstgCnsntStrYmd && lv_Vm.showEvtDtl?.mcnstCnsntStrDtm && lv_Vm.showEvtDtl?.mcnstCnsntEndDtm) {
                isRangeCheck = lv_Vm.fn_CheckBetweenDate(lv_Vm.custInfo.mndtCnstgCnsntStrYmd, lv_Vm.showEvtDtl.mcnstCnsntStrDtm, lv_Vm.showEvtDtl.mcnstCnsntEndDtm)
              }
              let altMsg = ''
              if (!isNewCust) {
                lv_Vm.getStore('confirm').dispatch('ADD', '이벤트 참여조건에 맞지 않습니다.')
                altMsg = `고객카드생성년월/필수컨설팅동의받은시작년월: ${custCardRegMdfcVO.iCHoshInfoListInqrVO[0].crdat.substring(0, 6)}/${lv_Vm.custInfo.mndtCnstgCnsntStrYmd.substring(0, 6)}\n`+
                         `이벤트 필수컨설팅동의시작날짜: ${lv_Vm.showEvtDtl.mcnstCnsntStrDtm.substring(0, 6)}`
                console.log(altMsg)
                // alert(altMsg)
                return false
              } else {                
                lv_Vm.custInfo.newMndtCnstgCnsntYn = isRangeCheck ? 'Y' : 'N'
                altMsg = `고객카드생성년월/필수컨설팅동의받은시작년월: ${custCardRegMdfcVO.iCHoshInfoListInqrVO[0].crdat.substring(0, 6)}/${lv_Vm.custInfo.mndtCnstgCnsntStrYmd.substring(0, 6)}\n`+
                       `필수컨설팅동의시작날짜: ${lv_Vm.custInfo.mndtCnstgCnsntStrYmd}\n`+
                       `이벤트 필수동의받은기간: ${lv_Vm.showEvtDtl.mcnstCnsntStrDtm}~${lv_Vm.showEvtDtl.mcnstCnsntEndDtm}: ${isRangeCheck}\n`+
                       `결과값: ${lv_Vm.custInfo.newMndtCnstgCnsntYn}`
                // lv_Vm.custInfo.newMndtCnstgCnsntYn = custCardRegMdfcVO.iCHoshInfoListInqrVO[0].crdat.substring(0, 6) === lv_Vm.custInfo.mndtCnstgCnsntStrYmd.substring(0, 6) ? 'Y' : 'N'
                console.log(altMsg)
                // alert(altMsg)
              }              
              lv_Vm.fn_GetCnsntInfo(lv_Vm.custInfo)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*******************************************************************************
      * Function명: fn_CheckBetweenDate
      * 설명: 체크 날짜가 시작일자, 종료일자 내 체크 여부
      *******************************************************************************/
      fn_CheckBetweenDate(checkDate, startDate, endDate) {
        let rtn = false
        if(checkDate && startDate && endDate) {
          // let today = moment(new Date()).format('YYYYMMDD')
          let endDatePlusOne = moment(endDate).add(1, 'day').format('YYYYMMDD')
          rtn = moment(checkDate).isBetween(startDate, endDatePlusOne)
        }
        return rtn
      },
      /*******************************************************************************
      * Function명: fn_GetCnsntInfo
      * 설명: 마케팅, 필수컨설팅 동의에 따른 바텀영역 제어
      *******************************************************************************/
      fn_GetCnsntInfo (param) {
        this.$refs.nsbottomsheet2.close()
        this.$refs.nsbottomsheet3.close()
        console.log('고객정보 확인 fn_GetCnsntInfo', param)
        console.log('필컨 동의 필요 조건 이벤트>>>', this.showEvtDtl.mcnstCnsntCondYn === 'Y')
        this.bottomMessage = ''
        //this.infoPrcusCnsntTypCd = ''
        if (param.custNm !== '') { // 검색한 이름 있는 경우만
          if (this.showEvtDtl.mcnstCnsntCondYn === 'Y') { // 필컨 동의 필요 이벤트
            // ASR230500413 [사랑On] 이벤트(룰렛사다리) 내용 변경 요청 : 신규동의 체크
            if (this.showEvtDtl.newCnsntYn === 'Y') { //신규동의 조건 Y
              // 고객카드(에이젼트파일 ID) 생성년월 = 동의 승인년월 동일 시, 신규동의로 간주
              console.log('신규필컨 YN >>>', param.newMndtCnstgCnsntYn)
              console.log('마케팅< >>>', param.mktCnsntYn)
              if (param.newMndtCnstgCnsntYn === 'N' && param.mktCnsntYn === 'N') {
                this.bottomMessage = '마케팅과 신규 필수컨설팅 동의 후 참여가 가능합니다.'
                console.log('신규동의 Y 둘다 미동의', this.bottomMessage)
                this.$refs.nsbottomsheet3.open()
                return
              } else if (param.newMndtCnstgCnsntYn === 'N' && param.mktCnsntYn === 'Y') {
                this.bottomMessage = '신규 필수컨설팅 동의 후 참여가 가능합니다.'
                console.log('신규동의 Y 필컨 미동의', this.bottomMessage)
                this.$refs.nsbottomsheet3.open()
                return
              } else if (param.newMndtCnstgCnsntYn === 'Y' && param.mktCnsntYn === 'N') {
                this.bottomMessage = '마케팅 동의 후 참여가 가능합니다.'
                console.log('신규동의 Y 마케팅 미동의', this.bottomMessage)
                this.$refs.nsbottomsheet3.open()
                return
              }
            } else { // 신규동의 조건 N
              if (param.mndtCnstgCnsntYn === 'N' && param.mktCnsntYn === 'N') { //둘다 미동의
                this.bottomMessage = '마케팅과 필수컨설팅 동의 후 <br /> 참여가 가능합니다.'
                console.log('신규동의 N 둘다 미동의')
                this.$refs.nsbottomsheet3.open()
                return
              } else if (param.mndtCnstgCnsntYn === 'N' && param.mktCnsntYn === 'Y') { // 필수컨설팅만 미동의
                //this.infoPrcusCnsntTypCd = '19'
                this.bottomMessage = '필수컨설팅 동의 후 참여가 가능합니다.'
                console.log('신규동의 N 필컨 미동의')
                this.$refs.nsbottomsheet3.open()
                return
              } else if (param.mndtCnstgCnsntYn === 'Y' && param.mktCnsntYn === 'N') {
                //this.infoPrcusCnsntTypCd = '17'
                this.bottomMessage = '마케팅 동의 후 참여가 가능합니다.'
                console.log('신규동의 N 마케팅 미동의')
                this.$refs.nsbottomsheet3.open()
                return
              }
            }
            console.log('this.custInfo.mndtCnstgCnsntStrYmd>>', this.custInfo.mndtCnstgCnsntStrYmd)
            console.log('this.showEvtDtl.mcnstCnsntStrDtm>>', this.showEvtDtl.mcnstCnsntStrDtm)
            console.log('this.showEvtDtl.mcnstCnsntEndDtm>>', this.showEvtDtl.mcnstCnsntEndDtm)
            /* if (Number(this.custInfo.mndtCnstgCnsntStrYmd) < Number(this.showEvtDtl.mcnstCnsntStrDtm)) {
              this.bottomMessage = '필수컨설팅 동의 후 참여가 가능합니다.'
              console.log('필컨 미동의1')
              this.$refs.nsbottomsheet3.open()
              return
            } else if (Number(this.custInfo.mndtCnstgCnsntStrYmd) > Number(this.showEvtDtl.mcnstCnsntEndDtm)) {
              this.bottomMessage = '필수컨설팅 동의 후 참여가 가능합니다.'
              console.log('필컨 미동의2')
              this.$refs.nsbottomsheet3.open()
              return
            } */
          } else { // 필컨 동의 필요없음
            if (param.mktCnsntYn === 'N') {
              this.bottomMessage = '마케팅 동의 후 참여가 가능합니다.'
              this.$refs.nsbottomsheet3.open()
              return
            }
          }
          if (this.custInfo.celno === '' || this.custInfo.celno === ' ') {
            this.bottomMessage = '휴대폰정보 등록 후 참여가 가능합니다.'            
            this.$refs.nsbottomsheet3.open()
            return
          }
          let lv_Vm = this
  
          let pParams = {
            'mobslEvtId': lv_Vm.showEvtDtl.mobslEvtId,
            'mobslChnlCustId': lv_Vm.custInfo.chnlCustId
          }
          let trnstId = 'txTSSSA31S3'
          let auth = 'S'
          this.post(lv_Vm, pParams, trnstId, auth)
            .then(function (response) {
              console.log('참여정보 확인!!!!', response.body)
              if (response.body !== null && response.body !== '') {
                if (response.body.mobslEvtPtptYn === 'Y') {
                  lv_Vm.custInfo.ptptScCd = '3' // 이벤트 참여 완료
                  lv_Vm.isAlert = true
                  lv_Vm.$refs.nsbottomsheet2.open()
                } else if (response.body.orgPtptPsbYn === 'N') {
                  lv_Vm.isAlert = true
                  lv_Vm.showEvtDtl.ptptScCd = '1'
                  lv_Vm.$refs.nsbottomsheet1.open()
                } else if (response.body.fcCustPtptRstcNts === response.body.ptpFgr) {
                  lv_Vm.showEvtDtl.ptptScCd = '1'
                  lv_Vm.isAlert = true
                  lv_Vm.$refs.nsbottomsheet1.open()
                } else {
                  lv_Vm.custInfo.ptptScCd = '2'
                  lv_Vm.showEvtDtl.mobslEvtObjOrgTypCd = response.body.mobslEvtObjOrgTypCd // 이벤트대상 조직유형
                  lv_Vm.showEvtDtl.winGudnCntnt = response.body.winGudnCntnt // 당첨 안내내용
                  lv_Vm.isAlert = true
                  lv_Vm.$refs.nsbottomsheet2.open()
                  console.log('mobslEvtObjOrgTypCd: ' + lv_Vm.showEvtDtl.mobslEvtObjOrgTypCd)
                  console.log('winGudnCntnt: ' + lv_Vm.showEvtDtl.winGudnCntnt)
                }
                lv_Vm.$forceUpdate()
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
              }
  
            })
            .catch(function (error) {
              window.vue.error(error)
            })
        }
      },
    }
  };
</script>
<style scoped>
</style>
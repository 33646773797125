/*
* 업무구분: 룰렛 이벤트 참여
* 화면 명: MSPSA037P
* 화면 설명: 룰렛 이벤트 참여
* 화면 접근권한:
*/
<template>
  <ur-page-container v-if="!isLoading" class="msp" title="룰렛 이벤트 참여" :show-title="true" type="subpage" no-back :topButton="false" @on-header-left-click="fn_close">
    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-sa-wrap"> 
      <ur-box-container direction="column" alignV="start" class="">
        <div class="top-info">
          <div class="info-block">
            <span class="info-txt">
              <span class="txt fs16rem fwb" v-if="start_roulette === false">스타트를 누르세요.</span>
              <span class="txt fs16rem fwb" v-if="start_roulette === true">제발 두근두근<br>비싼거 되라~~~~^^ </span>
            </span>
          </div>
        </div>
        <!-- gift05 / gift04 / gift03 선물수에 따라 변경하여 사용하세요 -->
        <div class="event-roulette-area mt40" :class="{'gift03' : templBkgTypCd === '3', 'gift04' : templBkgTypCd === '4', 'gift05' : templBkgTypCd === '5', 'start': start_roulette === true, 'gift-second': set_roulette === '2', 'gift-third': set_roulette === '3', 'gift-fourth': set_roulette === '4', 'gift-fifth': set_roulette === '5', }" > 
          <div class="roulette-block">
            <div class="roulette-pin" />
            <mo-button class="roulette-start" @click="start_btn" />
            <div class="roulette-txt" >
              <div class="gift-txt">
                <span class="txt fwb fs16rem">1등</span>
                <span class="txt">{{ winGiftInfo.winGiftNm01 }}</span>
                <span class="txt">({{ winGiftInfo.mobslWinGiftFgr01 }}명)</span>
              </div>  

              <div class="gift-txt">
                <span class="txt fwb fs16rem">2등</span>
                <span class="txt">{{ winGiftInfo.winGiftNm02 }}</span>
                <span class="txt">({{ winGiftInfo.mobslWinGiftFgr02 }}명)</span>
              </div>

              <div class="gift-txt">
                <span class="txt fwb fs16rem">3등</span>
                <span class="txt">{{ winGiftInfo.winGiftNm03 }}</span>
                <span class="txt">({{ winGiftInfo.mobslWinGiftFgr03 }}명)</span>
              </div>

              <div class="gift-txt">
                <span class="txt fwb fs16rem">4등</span>
                <span class="txt">{{ winGiftInfo.winGiftNm04 }}</span>
                <span class="txt">({{ winGiftInfo.mobslWinGiftFgr04 }}명)</span>
              </div>

              <div class="gift-txt">
                <span class="txt fwb fs16rem">5등</span>
                <span class="txt">{{ winGiftInfo.winGiftNm05 }}</span>
                <span class="txt">({{ winGiftInfo.mobslWinGiftFgr05 }}명)</span>
              </div>
            </div>
          </div>          
        </div>

      </ur-box-container>
    </ur-box-container>

    <ur-box-container alignV="start" componentid="" direction="column" class="ns-btn-relative">
      <div class="relative-div">
        <mo-button componentid="" color="primary" shape="border" size="medium" class="ns-btn-round blue" name="닫기" :disabled="isBtnableYn" @click="fn_close">닫기</mo-button>
      </div>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
  /***********************************************************************************
   * 공통 라이브러리 INCLUDE 영역	                                                   *
   ***********************************************************************************/
  import MSPSA033P from '@/ui/sa/MSPSA033P.vue'
  export default {
    /***********************************************************************************
     * Vue 파일 속성 정의 영역	                                                       *
     ***********************************************************************************/
    name: "MSPSA037P",
    screenId: "MSPSA037P",
    components: {
    },
    props: {
      pTemplBkgTypCd: {
        type: String,
        default: ''
      },
      pShowEvtDtl: {
        type: Object,
        default: {}
      },
      pCustInfo: {
        type: Object,
        default: {}
      }
    },
    // bottom-sheet Full Popup
    modalProps: {
      mode: 'right' // 우->좌 open popup (defalut: 하->상)
    },
    /***********************************************************************************
     * Life Cycle 함수 정의 영역	                                                     *
     ***********************************************************************************/
    created() {
      this.fn_Retrieve()
    },
    mounted() {
      this.$bizUtil.insSrnLog('MSPSA037P')
      this.templBkgTypCd = this.pTemplBkgTypCd
      this.custInfo = this.pCustInfo
      console.log('this.custInfo>>', this.custInfo)
    },
    /***********************************************************************************
     * 화면변수 선언 영역	                                                             *
     ***********************************************************************************/
    data() {
      return {
        isLoading: true,
        templBkgTypCd : '', // 룰렛 판 갯수
        winGiftInfo: {}, // 당첨 선물
        IsMSPSA033P: {}, // 당첨 결과 팝업
        start_roulette: false, // 룰렛 start 기본값;
        set_roulette: '1', // 룰렛 결과(ex: 1: 1등 , 2: 2등...)
        rsltInfo: {}, // 당첨 결과
        custInfo: {}, // 고객정보
        isBtnableYn: false, // 하단 닫기버튼 비활성화 여부
      }
    },
    watch: {},
    /***********************************************************************************
     * Computed 함수 정의 영역                                                         *
     ***********************************************************************************/
    computed: {},
    /***********************************************************************************
     * 사용자 함수 정의 영역                                                           *
     ***********************************************************************************/
    methods: {
      start_btn () {
        this.start_roulette=!this.start_roulette
        this.isBtnableYn = true
        this.getStore('progress').dispatch('UPDATE', true)
        setTimeout(() => {
            this.start_roulette = false;
            this.getStore('progress').dispatch('UPDATE', false)
            this.fn_GetRslt()
          }, 1000);
        
      },
      /*******************************************************************************
      * Function명: fn_Retrieve
      * 설명: 당첨선물 정보 조회
      *******************************************************************************/
      fn_Retrieve () {
        let lv_Vm = this

        let pParams = {
          'mobslEvtId': this.pShowEvtDtl.mobslEvtId
        }

        let trnstId = 'txTSSSA37S1'
        let auth = 'S'

        lv_Vm.isLoading = true

        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.winGiftInfo = response.body
              console.log('당첨선물 정보 조회>>>>>>', lv_Vm.winGiftInfo)
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      },
      /*******************************************************************************
      * Function명: fn_GetRslt
      * 설명: 당첨결과 저장
      *******************************************************************************/
      fn_GetRslt () {
        let lv_Vm = this

        let pParams = {
          'mobslEvtId': this.pShowEvtDtl.mobslEvtId,
          'mobslEvtObjOrgTypCd': this.pShowEvtDtl.mobslEvtObjOrgTypCd,
          'mobslChnlCustId': this.custInfo.chnlCustId,
          'custNm': this.custInfo.custNm,
          'custCelno': this.custInfo.celno.replaceAll('-', ''),
          'mobslCustId': this.custInfo.custId
        }

        let trnstId = 'txTSSSA37I1'
        let auth = 'I'


        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            if (response.body !== null && response.body !== '') {
              lv_Vm.rsltInfo = response.body
              console.log('당첨결과>> response>>', response)
              console.log('당첨결과>> rsltInfo>>', lv_Vm.rsltInfo)
              lv_Vm.fn_OpenMSPSA033P()
            } else {
              lv_Vm.lv_BtnClass = ''
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

          })
          .catch(function (error) {
            console.log('당첨결과 조회 error!!>>', error)
            window.vue.error(error)
          })
      },
      /*******************************************************************************
      * Function명: fn_OpenMSPSA037P
      * 설명: 당첨결과 팝업 오픈
      *******************************************************************************/
      fn_OpenMSPSA033P() {
      this.IsMSPSA033P = this.$bottomModal.open(MSPSA033P, {
        properties: {
          pRsltInfo: this.rsltInfo,
          pCustInfo: this.custInfo,
          pEvtInfo: this.pShowEvtDtl
          
        },
        listeners: {
          onPopupConfirm: () => {
            this.$emit('onPopupConfirm')
            this.$bottomModal.close(this.IsMSPSA033P)
          }
        }
      })
      },
      /*******************************************************************************
      * Function명: fn_close
      * 설명: 룰렛 팝업 닫기
      *******************************************************************************/
      fn_close() {
        this.$emit('onPopupConfirm')
      }
    }
  };
</script>
<style scoped>
</style>